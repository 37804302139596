<template>
  <div id="personalPage">
    <TabbarTopNav :newMessage="newMessage" class="tabbar-top-nav">
      <template slot="avatar"><span></span></template>
    </TabbarTopNav>
    <div class="container">
      <div class="header">
        <router-link :to="{ name: 'BaseInfoIndex' }" class="header-content">
          <div class="left">
            <van-image
              round
              class="avatar"
              fit="cover"
              :src="personInfo.avatarTemp || require('@/assets/images/avatarDefault_01.png')"
            />
            <template v-if="personInfo.jurisdiction">
              <i :class="'icon icon-vip ' + getAvatarIcon"></i>
            </template>
          </div>
          <div class="right">
            <div class="name" v-if="personInfo.name">{{ personInfo.name }}</div>
            <div class="name" v-else>ニックネームを設定してください</div>
            <i class="icon icon-edit" ></i>
          </div>
        </router-link>
      </div>
      <div class="section section-1">
        <div class="section-content">
          <router-link class="item" :to="item.to" v-for="(item, index) in navList" :key="index">
            <div class="thumb">
              <i class="icon" :class="[item.icon]"></i>
            </div>
            <div class="name">{{item.name}}</div>
          </router-link>
        </div>
      </div>
      <div class="section section-2">
        <div class="section-content">
          <router-link class="item" :to="item.to" v-for="(item, index) in menuList" :key="index">
            <div class="left">
              <i class="icon" :class="[item.icon]"></i>
              <div class="name">{{item.name}}</div>
            </div>
            <div class="right">
              <van-icon name="arrow" />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TabbarTopNav } from '@/components';
import { mapGetters } from 'vuex';

export default {
  name: 'Personal',
  components: {
    TabbarTopNav
  },
  data () {
    return {
      person: {

      },
      newMessage: false,
      navList: [{
        icon: 'icon-1',
        name: 'お気に入り',
        to: { name: 'CollectList' }
      }, {
        icon: 'icon-2',
        name: '購入済み',
        to: { name: 'PurchaseList' }
      }, {
        icon: 'icon-3',
        name: 'マイクーポン',
        to: { name: 'CouponList' }
      }],
      menuList: [{
        icon: 'icon-1',
        name: '会員センター',
        to: { name: 'MemberIndex' }
      }, {
        icon: 'icon-4',
        name: '購入履歴',
        to: { name: 'MemberOrderList' }
      }, {
        icon: 'icon-2',
        name: 'お問い合わせ',
        to: { name: 'Feedback' }
      }, {
        icon: 'icon-3',
        name: '設置',
        to: { name: 'SettingIndex' }
      }]
    };
  },
  computed: {
    ...mapGetters(['personInfo']),
    getAvatarIcon () {
      const { personInfo: { jurisdiction } } = this;
      let className = '';
      if (jurisdiction.includes(4)) {
        className = 'icon-vip-1';
      } else if (jurisdiction.includes(1) && jurisdiction.includes(3)) {
        className = 'icon-vip-2';
      } else if (jurisdiction.includes(3)) {
        className = 'icon-vip-3';
      }
      return className;
    }
  },
  mounted () {
    this.getMessage();
  },
  watch: {
  },
  methods: {

    /* 获取消息信息 */
    getMessage () {
      const { $http } = this;
      $http.get('homePage/isExistUnread', { customError: true }).then((res) => {
        this.newMessage = res.data;
      });
    }

  }
};
</script>
<style scoped lang="less">
  #personalPage {
    display: flex;
    flex-direction: column;
    height: 100%;

    /deep/
    .tabbar-top-nav {
      .avatar {
        display: none;
      }

      .icon-vip {
        display: none;
      }

      .search-box {
        margin-left: 0;
      }
    }

    .container {
      height: 100%;
      overflow-y: auto;

      .header {
        padding: 40px 40px 48px;

        .header-content {
          &,
          .right {
            display: flex;
            align-items: center;
          }

          .left {
            position: relative;
            flex-shrink: 0;
            width: 112px;
            height: 112px;
            margin-right: 28px;

            .avatar {
              width: 100%;
              height: 100%;
            }

            .icon-vip {
              position: absolute;
              right: 0;
              bottom: 0;
              z-index: 1;
              width: 32px;
              height: 28px;

              &.icon-vip-1 {
                background-image: url("~@/assets/images/icon_vip_03.png");
              }

              &.icon-vip-2 {
                background-image: url("~@/assets/images/icon_vip_05.png");
              }

              &.icon-vip-3 {
                background-image: url("~@/assets/images/icon_vip_06.png");
              }
            }
          }

          .right {
            flex: 1;

            .name {
              flex: 1;
              font-size: 30px;
              line-height: 40px;
            }

            .icon-edit {
              flex-shrink: 0;
              width: 40px;
              height: 40px;
              background-image: url("~@/assets/images/icon_edit_01.png");
            }
          }
        }
      }

      .section {
        padding-right: 28px;
        padding-left: 28px;
        margin-bottom: 28px;

        &.section-1 {
          .section-content {
            display: flex;
            padding: 28px;
            font-size: 20px;
            line-height: 30px;
            background-color: #2d2e2e;
            border-radius: 16px;

            .item {
              display: flex;
              flex: 1;
              flex-direction: column;
              align-items: center;
              width: 0;
              padding-right: 10px;
              padding-left: 10px;

              .thumb {
                margin-bottom: 8px;

                .icon {
                  width: 80px;
                  height: 80px;

                  &.icon-1 {
                    background-image: url("~@/assets/images/icon_personNav_01.png");
                  }

                  &.icon-2 {
                    background-image: url("~@/assets/images/icon_personNav_02.png");
                  }

                  &.icon-3 {
                    background-image: url("~@/assets/images/icon_personNav_03.png");
                  }
                }
              }
            }
          }
        }

        &.section-2 {
          .section-content {
            font-size: 26px;
            line-height: 34px;

            .item {
              padding: 24px 20px;

              &:not(:last-child) {
                margin-bottom: 16px;
              }

              &,
              .left,
              .right {
                display: flex;
                align-items: center;
              }

              .left {
                flex: 1;
                width: 0;

                .icon {
                  flex-shrink: 0;
                  width: 48px;
                  height: 48px;
                  margin-right: 24px;

                  &.icon-1 {
                    background-image: url("~@/assets/images/icon_personMenu_01.png");
                  }

                  &.icon-2 {
                    background-image: url("~@/assets/images/icon_personMenu_02.png");
                  }

                  &.icon-3 {
                    background-image: url("~@/assets/images/icon_personMenu_03.png");
                  }
                  &.icon-3 {
                    background-image: url("~@/assets/images/icon_personMenu_03.png");
                  }
                  &.icon-4 {
                    background-image: url("~@/assets/images/icon_personMenu_04.png");
                  }
                }
              }

              .right {

              }
            }
          }
        }
      }
    }
  }
</style>